/* PDFUploadPage.css */

.pdf-upload-page .react-pdf__Page__canvas {
  /* PDF 페이지 캔버스에 대한 스타일링 */
  width: 100% !important; /* 가로 폭을 100%로 설정하여 컨테이너에 맞게 조정합니다. */
  height: auto !important; /* 세로 높이를 자동으로 설정하여 비율을 유지합니다. */
  border: none !important; /* 테두리가 없도록 설정합니다. */
}

.pdf-upload-page .react-pdf__Document {
  /* PDF 문서 컨테이너에 대한 스타일링 */
  border: none !important; /* 테두리가 없도록 설정합니다. */
  box-shadow: none !important; /* 그림자를 제거합니다. */
}

.react-pdf__Page__textContent {
  display: none;
}

/* Optionally, hide annotations layer if it's also not needed */
.react-pdf__Page__annotations {
  display: none;
}

.stickyHeader {
  position: sticky;
  top: 0;
  background: white;
  padding: 10px 0;
  z-index: 1000;
}
